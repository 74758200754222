import "../css/app.css";
import "@/bootstrap";
import * as Sentry from "@sentry/react";

import { createInertiaApp } from "@inertiajs/react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { ThemeProvider } from "./components/theme-provider";
import { Toaster } from "./components/ui/sonner";
import { StrictMode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

import.meta.glob(["../images/**"]);

const appName = import.meta.env.VITE_APP_NAME || "OnyxAnywhere";

createInertiaApp({
    title: (title) => `${title} · ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./pages/${name}.tsx`,
            import.meta.glob("./pages/**/*.tsx"),
        ),
    setup({ el, App, props }) {
        const queryClient = new QueryClient();

        const root = (
            <StrictMode>
                <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
                    <QueryClientProvider client={queryClient}>
                        <App {...props} />
                        <Toaster richColors className="" />
                    </QueryClientProvider>
                </ThemeProvider>
            </StrictMode>
        );

        Sentry.init({
            dsn: import.meta.env.VITE_SENTRY_DSN,
            release: import.meta.env.VITE_SENTRY_RELEASE,
            environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
            tracesSampler: ({ parentSampled }) => {
                if (typeof parentSampled === "boolean") {
                    return parentSampled;
                }

                return import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE;
            },
            profilesSampleRate: import.meta.env
                .VITE_SENTRY_PROFILES_SAMPLE_RATE,
            integrations: [
                Sentry.feedbackIntegration({
                    autoInject: false,
                    triggerLabel: "Feedback",
                    formTitle: "ONYX Feedback",
                    submitButtonLabel: "Send Feedback",
                    messagePlaceholder: "Request a feature or report an issue.",
                    successMessageText: "Thank you for your feedback!",
                    showBranding: false,
                    trackComponents: true,
                    logErrors: true,
                }),
            ],
        });

        if (import.meta.env.SSR) {
            hydrateRoot(el, root);
            return;
        }

        createRoot(el).render(root);
    },
    progress: {
        color: "#4B5563",
    },
});
